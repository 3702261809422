<template>
    <div class="page">
        <HeadContent>
            <img slot="bgImg" src="../../assets/common/topTitleBg.png" style="margin-top: 70px">
            <div slot="title" style="width: 100%">我们的承诺</div>
        </HeadContent>

        <div class="container">
            <div class="zjtd">
                <div class="people-box">
                    <div class="people-col" @click="goRouter('保密承诺')">
                        <img src="../../assets/images/aboutUs/pr1.png" alt="">
                        <div class="info">
                            <div class="p-name">保密承诺</div>
                        </div>
                    </div>
                    <div class="people-col" @click="goRouter('公正性声明')">
                        <img src="../../assets/images/aboutUs/pr2.png" alt="">
                        <div class="info">
                            <div class="p-name">公正性声明</div>
                        </div>
                    </div>
                    <div class="people-col" @click="goRouter('服务承诺')">
                        <img src="../../assets/images/aboutUs/pr3.png" alt="">
                        <div class="info">
                            <div class="p-name">服务承诺</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            goRouter(title) {
                this.$router.push({
                    name: 'Declaration',
                    query: {
                        title
                    }
                })
            }
        }
    }
</script>

<style lang="scss">
    .zjtd {
        margin-top: 100px;
        width: 100%;

        .people-box {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            .people-col {
                width: 30%;

                &:hover {
                    cursor: pointer;
                }
                &:hover .info {
                    border: none;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25)
                }

                img {
                    width: 100%;
                    height: auto;
                }

                .info {
                    box-sizing: border-box;
                    padding: 50px 60px;
                    text-align: center;
                    /*border: 1px solid #707070;*/
                    background-color: #fbfbfb;
                    border-top: 0;
                    margin-top: -4px;

                    div:nth-child(1) {
                        font-size: 24px;
                        letter-spacing: 4px;
                    }

                    div:nth-child(2) {
                        margin-top: 8px;
                        font-size: 14px;
                        color: #888;
                    }

                    div:nth-child(3) {
                        font-size: 14px;
                        color: #888;
                    }
                }
            }
        }
    }
</style>
